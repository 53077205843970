import { Link } from "gatsby"
import React from "react"

const ContentNavigation = ({color, title, pages, heading}) => (
  <nav className={`o-content-navigation o-content-navigation--${color}`}>
    {heading(title)}
    <ul className="menu">
      {
        pages.map((page, key) => (
          <li key={key}>
            <Link to={page.slug}>
              {page.title}
              <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                <path d="M1 9L5 5L1 1" stroke="#1E2E2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </Link>
          </li>
        ))
      }
    </ul>
  </nav>
)

export default ContentNavigation
