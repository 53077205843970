import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { graphql } from "gatsby"
import ContentNavigation from "../components/content-navigation"

export default function Diagnostyka({ data }) {
  const seo = {
    title: "Diagnostyka - badania które możesz wykonać w Medicus Dukla",
    description: "W Medicus Dukla czekają na Ciebie następujące badania: densytometria, audiometria, EKG, RTG, spirometria, usg",
    url: "https://medicusdukla.pl/diagnostyka"
  }

  const pages = data.pages.edges.map(({ node }) => ({
    ...node
  }))

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section data-g="container">
        <ContentNavigation
          color="blue"
          title="Diagnostyka"
          pages={pages}
          heading={title => (<h1>{title}</h1>)}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
        pages: allDiagnostykaYaml {
            edges {
                node {
                    title
                    slug
                }
            }
        }
    }
`
